body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'TT Norms Pro, Roboto, sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
}
